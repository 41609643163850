






























































































































import CenteredColumnLayout from "@/components/CenteredColumnLayout.vue";
import { InputSetups } from "@/mixins/input-setups";
import {
  createNetwork,
  getMyNetworks,
  initialiseNetworkModel,
} from "@/includes/logic/Networks/logic";
import NetworksHelperMixin from "@/includes/logic/Networks/NetworksHelperMixin";

import ChipsInput from "piramis-base-components/src/components/Pi/fields/ChipsInput/ChipsInput.vue";
import TextInput from "piramis-base-components/src/components/Pi/fields/TextInput/TextInput.vue";
import SelectInput from "piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue";
import { FieldData, SelectOptionData } from "piramis-base-components/src/components/Pi/types";
import PageTitle from "piramis-base-components/src/components/PageTitle.vue";
import SwitchInput from "piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue";
import NestedContent from "piramis-base-components/src/components/Pi/components/NestedContent.vue";

import { Component, Mixins } from 'vue-property-decorator';
import { ValidationObserver } from "vee-validate";

const NETWORK_NAME_MIN_SYMBOLS = '9'

@Component({
  data() {
    return {
      NETWORK_NAME_MIN_SYMBOLS
    }
  },
  components: {
    PageTitle,
    TextInput,
    SelectInput,
    ChipsInput,
    SwitchInput,
    CenteredColumnLayout,
    ValidationObserver,
    NestedContent
  }
})
export default class NetworksAdd extends Mixins(InputSetups, NetworksHelperMixin) {
  networkModel = initialiseNetworkModel()

  isPublic = false

  networksChats: Array<number> | null = null

  networkNameSetter(args: FieldData) {
    args.setter = (value: string) => {
      const trimValue = value.trim()
      const regex = /^[a-zA-Z_0-9]+$/gi

      if (regex.test(trimValue) || !trimValue.length) {
        args.model[args.key] = trimValue
      }
    }

    return args
  }

  get chatOptions(): Array<SelectOptionData> {
    const baseChats = this.$store.getters.chatSelectOptions

    if (this.networksChats) {
      return baseChats.filter((c: SelectOptionData) => !this.networksChats?.includes(c.value))
    }

    return baseChats
  }

  createNetworkVm() {
    this.$baseTemplate.loader.open()

    createNetwork(this.networkModel)
      .then(res => {
        this.$baseTemplate.loader.close()

        if (res) {
          this.gotoNetDashboard(res.id)
        }
      })
  }

  mounted() {
    getMyNetworks()
      .then(res => {
        this.networksChats = res.map(n => n.chats).flat()
      })
  }
}
